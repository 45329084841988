import firebase from 'firebase'

const firebaseConfig = {
  apiKey: 'AIzaSyCQ0TcUqa6-aN1cau6WvNtGH0LeWO9Dqg0',
  authDomain: 'irsassociation-c439f.firebaseapp.com',
  databaseURL: 'https://irsassociation-c439f.firebaseio.com',
  projectId: 'irsassociation-c439f',
  storageBucket: 'irsassociation-c439f.appspot.com',
  messagingSenderId: '270889550896',
  appId: '1:270889550896:web:0145776b5b0295ee8e99f5',
  measurementId: 'G-X7Q7MJ18CY',
}

firebase.initializeApp(firebaseConfig)
// firebase.analytics();

export default firebase
